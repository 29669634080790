import IconLink from "../component/IconLink";
import React from "react";

export const address = {
    name : "Angelo Aracri",
    street : "Klosterweg 28 D205",
    city : "76131 Karlsruhe",
    country : "GERMANY",

    mail: "contact@angeloaracri.de",
    mail_legal: "legal@angeloaracri.de",

    phone: "+49 1578 096 1033",

    linkGitHub: "https://github.com/AnJ95",
    linkLinkedIn: "https://www.linkedin.com/in/angelo-aracri-72654b287",
    linkTelegram: "https://t.me/an_ge_low",

    getCompleteBlock() {
        return <p>
            {this.name}<br />
            {this.street}<br />
            {this.city}<br />
            {this.country}<br />
            <IconLink icon="envelope" href={"mailto:" + this.mail_legal}>{this.mail_legal}</IconLink><br />
            <IconLink icon="phone" href={"tel:" + this.phone}>{this.phone}</IconLink><br />
        </p>
    }
}

/*
export function arrayEquals(a, b) {
    return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index]);
}
*/

export function encodeNameToURI(name) {
    return encodeURIComponent(
        name
            .replaceAll(" ", "-")
            .replaceAll(":", "")
            .replaceAll(".", "-")
            .replaceAll("!", "")
            .toLowerCase()
    );
}

export function checkIsInViewport(element, overshoot=0) {
    const { top, bottom } = element.getBoundingClientRect()
    return (top >= 0 && top <= (window.innerHeight || document.documentElement.clientHeight) - overshoot) ||
        (bottom >= 0 && bottom <= (window.innerWidth || document.documentElement.clientWidth) - overshoot);
}